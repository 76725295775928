import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AcceptInvitationRequest,
  AddShopRequest,
  EditShopNameRequest,
  CreateShopLoginIDAndHashedPasswordRequest,
  SendInvitationsRequest,
  UpdateTenantStaffNameByClerkUserIDRequest,
  AddMessageRequest,
} from "schema/gen/es/foodlosscoupon/adminconsole/v1/adminconsole_pb";
import { PartialMessage } from "@bufbuild/protobuf";

import { api } from "../lib/api";

// export const useQueryGetTenantStaffByClerkID = (clerkUserId: string) => {
//   return useQuery({
//     queryKey: ["getTenantStaffByClerkID"],
//     queryFn: () =>
//       adminConsoleClient.getTenantStaffByClerkID({
//         clerkUserId,
//       }),
//   });
// };

export const useQueryGetTenant = () => {
  return useQuery({
    queryKey: ["getTenant"],
    queryFn: () => api.getTenant({}),
  });
};

export function useQueryGetTenantMessage() {
  return useQuery({
    queryKey: ["getTenantMessage"],
    queryFn: () => api.getTenantMessage({}),
  });
}

export const useQueryGetInvitation = (invitationId: string, email: string) => {
  return useQuery({
    queryKey: ["getInvitation"],
    queryFn: () => api.getInvitation({ invitationId, email }),
  });
};

export const useQueryGetShopsByTenantID = (pageNum: number, limit: number) => {
  return useQuery({
    queryKey: ["getShopsByTenantID"],
    queryFn: () => api.getShopsByTenantID({ pageNum, limit }),
  });
};

export const useQueryGetTenantStaffsByTenantID = (
  pageNum: number,
  limit: number
) => {
  return useQuery({
    queryKey: ["getTenantStaffsByTenantID"],
    queryFn: () => api.getTenantStaffsByTenantID({ pageNum, limit }),
  });
};

export function useMutationSendInvitations() {
  return useMutation({
    mutationKey: ["sendInvitations"],
    mutationFn: (args: PartialMessage<SendInvitationsRequest>) =>
      api.sendInvitations(args),
  });
}

export function useMutateAcceptInvitation() {
  return useMutation({
    mutationKey: ["acceptInvitation"],
    mutationFn: (args: PartialMessage<AcceptInvitationRequest>) =>
      api.acceptInvitation(args),
  });
}

export function useMutationAddShop() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["addShop"],
    mutationFn: (args: PartialMessage<AddShopRequest>) => api.addShop(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getShopsByTenantID"],
      });
    },
  });
}

export function useMutationAddMessage() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["addMessage"],
    mutationFn: (args: PartialMessage<AddMessageRequest>) =>
      api.addMessage(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTenantMessage"],
      });
    },
  });
}

export function useMutationEditShopName() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["editShopName"],
    mutationFn: (args: PartialMessage<EditShopNameRequest>) =>
      api.editShopName(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getShopsByTenantID"],
      });
    },
  });
}

export function useMutationCreateShopLoginIDAndHashedPassword() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createShopLoginIDAndHashedPassword"],
    mutationFn: (
      args: PartialMessage<CreateShopLoginIDAndHashedPasswordRequest>
    ) => api.createShopLoginIDAndHashedPassword(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getShopsByTenantID"],
      });
    },
  });
}

export function useUpdateTenantStaffNameByClerkUserID() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateTenantStaffNameByClerkUserID"],
    mutationFn: (
      args: PartialMessage<UpdateTenantStaffNameByClerkUserIDRequest>
    ) => api.updateTenantStaffNameByClerkUserID(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTenantStaffsByTenantID"],
      });
    },
  });
}
